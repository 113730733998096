<template>
    <div class="grey lighten-5 pb-0 mt-8">
      <v-row class="mt-2 pt-6" no-gutters>
        <v-col cols="5" class="px-4">
          <v-text-field outlined shaped prepend-inner-icon="mdi-database" v-model="dataset.name" label="NAME" :rules="[ v => !!v  || 'Required.']" hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="5" class="px-4">
          <v-select outlined shaped prepend-inner-icon="mdi-cog" v-model="dataset.datasetType" :items="datasetTypes" item-text="name" item-value="id" label="TYPE"></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-6" no-gutters>
        <v-col cols="5" class="px-4">
          <v-text-field :value="dataset.createdAt" prepend-inner-icon="mdi-calendar-plus" label="CREATED" :hint="dataset.createdAtAgo" persistent-hint readonly></v-text-field>
        </v-col>
        <v-col cols="5" class="px-4">
          <v-text-field :value="dataset.uploadedAt"
                        prepend-inner-icon="mdi-calendar-sync"
                        :label="dataset.uploadedAt ? 'UPLOADED' : 'NOT UPLOADED'"
                        :hint="dataset.uploadedAtAgo"
                        persistent-hint
                        readonly
                        :disabled="dataset.uploadedAt ? false : true"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-6" no-gutters>
        <v-col cols="5" class="px-4">
          <v-text-field :value="dataset.importedAt"
                        prepend-inner-icon="mdi-calendar-import"
                        :label="dataset.importedAt ? 'IMPORTED' : 'NOT IMPORTED'"
                        :hint="dataset.importedAtAgo"
                        :disabled="dataset.importedAt ? false : true"
                         persistent-hint
                         readonly></v-text-field>
        </v-col>
        <v-col cols="5" class="px-4">
          <v-text-field :value="dataset.fileName" prepend-inner-icon="mdi-form-textbox" :label="dataset.fileName ? 'FILE NAME' : 'NO FILE NAME'" readonly :disabled="dataset.fileName ? false : true"></v-text-field>
        </v-col>

      </v-row>
      <v-row class="mt-6" no-gutters>
        <v-col cols="5" class="px-4">
          <v-text-field :value="dataset.fileSize" :label="dataset.fileSize ? 'FILE SIZE' : 'NO FILE SIZE'" readonly :disabled="dataset.fileSize ? false : true"></v-text-field>
        </v-col>
        <v-col cols="2" class="px-4">
          <v-text-field :value="dataset.recordCount" :label="dataset.recordCount ? 'ROWS' : 'NO DATA'" readonly :disabled="dataset.recordCount ? false : true"></v-text-field>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="2" class="px-4">
          <v-text-field :value="dataset.columnCount" :label="dataset.columnCount ? 'COLUMNS' : 'NO DATA'" readonly :disabled="dataset.columnCount ? false : true"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-6" no-gutters>
        <v-col cols="5" class="px-4">
          <v-text-field :value="dataset.status.name" prepend-inner-icon="mdi-list-status" label="IMPORT STATUS" readonly></v-text-field>
        </v-col>
        <v-col cols="5" class="px-4">
          <v-text-field :value="datasetAnalysisStatus" prepend-inner-icon="mdi-list-status" label="ANALYSIS STATUS" readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-6" no-gutters>
        <v-col cols="10" class="d-flex justify-center" >
              <v-btn class="primary px-16 mt-4"  @click="onSave()" >
                Save
              </v-btn>
        </v-col>
      </v-row>
    </div>
</template>
<script>
import {getDataset, getDatasetTypes, updateDataset} from '@/api/dataset'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addLocale(en)

const timeAgo = new TimeAgo('en-UK')

  export default {
    name: 'DatasetInfo',
    data () {
      return {
        datasetTypes: [],
        datasetId: this.$route.params.id,
        dataset:{
          name:'',
          datasetType: 1,
          createdAt:'',
          fileName: ' ',
          status:{
            name:''
          }
        }
      }
    },
    computed:{
      datasetAnalysisStatus(){
        if (this.dataset.postprocessStatus === 'POSTPROCESSED'){
          return 'Analyzed'
        } else if (this.dataset.postprocessStatus === null){
          return 'NOT Analyzed'
        } else if (this.dataset.postprocessStatus === 'POSTPROCESSING'){
          return 'Analyzing'
        }
        return 'Unknown!'
      }
    },
    methods: {
      onSave(){
        const payload = {
          name: this.dataset.name,
          datasetType: this.dataset.datasetType
        }
        updateDataset(payload, this.$route.params.id, 'Dataset updated successfully')
      },
      toStrDate(dt){
        if ( dt ) {
          var d = new Date(dt)
          return d.toDateString() + '    ' + d.toLocaleTimeString()
        }
        else{
          return ''
        }
      },
      agoFormat(dt){
        if ( dt ) {
          var d = new Date(dt)
          return  timeAgo.format(d)
        }
        else{
          return ''
        }
      }
    },
    created () {
      const _this = this
      getDatasetTypes(function(res){
        _this.datasetTypes = res
      })
      getDataset(this.datasetId, function(ds){
        ds.createdAt = _this.toStrDate(ds.createdAt)
        ds.uploadedAt = _this.toStrDate(ds.uploadedAt)
        ds.importedAt = _this.toStrDate(ds.importedAt)
        ds.createdAtAgo = _this.agoFormat(ds.createdAt)
        ds.uploadedAtAgo = _this.agoFormat(ds.uploadedAt)
        ds.importedAtAgo = _this.agoFormat(ds.importedAt)

        if(ds.fileSize ){
          if (  ds.fileSize > 1024*1024 ){
            ds.fileSize =  Math.round((ds.fileSize/1024/1024)*100)/100 + ' MB'
          } else if ( ds.fileSize > 1024 ){
            ds.fileSize =  Math.round((ds.fileSize/1024)*10)/10 + ' KB'
          } else if (ds.fileSize !== null ){
            ds.fileSize = ds.fileSize + ' Bytes'
          }
        }
        _this.dataset = ds
      })
    }
  }
</script>
